/* Global Element Styles */

html {
  font-family: Roboto, sans-serif;
  box-sizing: border-box
}

*, *:before, *:after {
  box-sizing: inherit;
}

#page {
  margin: 0 auto;
  padding: 2em;
  max-width: 1000px;
}

hr {
  margin: 2em 0;
  height: 1px;
  background-color: #E0E0E0;
  border: none;
}

h3, h4, h5, h6 {
  margin: 0;
  margin-bottom: 1em;
}

#env-line {
  color: #FFF;
  background-color: #EB4438;
  padding: 5px;
  margin: 0;
}

.disable {
  display: none;
}

/* Grid */

.grid {
  cursor: default;
  margin: 24px auto;
  padding: 12px;
  background-color: #F5FDFF;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 12px;
}

.grid.alt-grid {
  background-color: #E2E2E2;
}

.grid.outline-grid {
  background-color: #FFF;
  border: 2px solid #E2E2E2;
  border-radius: 10px;
}

.grid *,
.grid button,
.grid input[type=button],
.grid input[type=text] {
  overflow: hidden;
  margin: 0;
  min-width: 0;
}

/* Use divs as full-width containers */
.grid > div > * {
  width: 100%;
}


.grid-hover:hover {
  background-color: #E3F8FF;
}

.grid-selected {
  background-color: #E3F8FF;
}

.grid-red {
  background-color: #FFCCCB;
}

.grid-1-2 {
  grid-column: 1 / 2;
}

.grid-1-3 {
  grid-column: 1 / 3;
}

.grid-1-4 {
  grid-column: 1 / 4;
}

.grid-1-5 {
  grid-column: 1 / 5;
}

.grid-2-3 {
  grid-column: 2 / 3;
}

.grid-2-4 {
  grid-column: 2 / 4;
}

.grid-2-5 {
  grid-column: 2 / 5;
}

.grid-3-4 {
  grid-column: 3 / 4;
}

.grid-3-5 {
  grid-column: 3 / 5;
}

.grid-4-5 {
  grid-column: 4 / 5;
}

.keyword-box {
  padding: 12px;
  overflow: hidden;
  border: 1px solid #E0E0E0;
}

.keyword-box > * {
  margin: 0;
}

.last-updated {
  font-size: 0.85em;
  text-align: right;
  color: #BBB;
}

.note {
  color: #BBB;
}

.small-text {
  font-weight: normal;
  font-size: 0.75em;
  padding: 0;
}

.center,
.center * {
  min-width: 50px;
  text-align: center;
  margin: auto;
}

.empty-container {
  text-align: center;
  min-height: 600px;
  line-height: 600px;
}

/* Flex */

.flex {
  display: flex;
}

/* Forms */

label,
input,
textarea,
button,
select {
  outline: none;
  display: block;
  padding: 5px;
  margin: 5px;
  margin-top: 10px;
  min-width: 200px;
}

label {
  font-weight: bold;
}

input, select {
  border: 1px solid #E0E0E0;
}

select:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

::placeholder {
  color: #E0E0E0;
}

.greyed,
input:disabled {
  color: #7C7C7C;
}

input.greyed {
  background-color: #FFF;
}

input.error {
  outline: 0;
  border: 1px solid #EB4438;
}

p.error {
  padding: 5px 0;
  color: #EB4438;
  font-size: 0.85em;
}

textarea {
  min-height: 100px;
  background-color: #EFEFEF;
  border: 0;
}

.extended-form {
  background-color: #EFEFEF;
}

.form-addon {
  padding: 10px;
  background-color: #EFEFEF;
}

/* Button */

button,
input[type=button],
input[type=submit] {
  min-height: 40px;
  color: #555;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  background-color: #E0E0E0;
}

button:hover,
input[type=button]:hover,
input[type=submit]:hover {
  background-color: #FFF;
}

input.btn-primary,
button.btn-primary {
  color: #FFF;
  background-color: #1EAEDB;
  border-color: #1EAEDB;
}

input.btn-primary:hover,
button.btn-primary:hover {
  color: #1EAEDB;
  background-color: #FFF;
}

input.btn-delete,
button.btn-delete {
  color: #FFF;
  background-color: #EB4438;
  border-color: #EB4438;
}

input.btn-delete:hover,
button.btn-delete:hover {
  color: #EB4438;
  background-color: #FFF;
}

button.small {
  width: 40px;
  min-height: 35px;
  height: 10px;
}

button:disabled,
input[type=button]:disabled,
input[type=submit]:disabled {
  color: #A0A0A0;
  background-color: #EFEFEF;
  border-color: #E0E0E0;
}

/* Download Links */

article.downloads {
  padding: 5px;
  border-radius: 10px;
  background-color: #EFEFEF;
}

a.download {
  display: block;
  margin: 15px;
  font-size: 1.25em;
}


/* Tables */

table {
  width: 100%;
}

table,
tr,
th,
td {
  border-collapse: collapse;
  text-align: left;
  padding: 6px;
}

th {
  padding-bottom: 12px;
}

th,
td {
  border-bottom: 1px solid #E8E8E8;
}
