/* Navbar */

nav ul {
  background: #203958;
  display: flex;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin: auto 1em;
  display: inline;
}

nav a {
  color: #FFF;
  text-decoration: none;
  font-weight: 600;
}

nav a:hover {
  color: #1EAEDB;
}

#nd-logo {
  height: 75px;
}

#user-info {
  margin-left: auto;
}

#user-info * {
  color: #E0E0E0;
  margin: auto;
  padding: 0;
}

#user-info button {
  cursor: pointer;
  color: #22DCC5;
  font-weight: bold;
  background-color: transparent;
  margin: 0;
  border: 0;
  padding: 0;
  min-width: 0;
  min-height: 0;
}

#user-info button:hover {
  color: #1EAEDB;
}

#user-info button:focus {
  outline: 0;
}
