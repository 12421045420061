/* Report Container */

#report-container {
  font-family: Calibri, sans-serif;
}

#report-container h3 {
  font-size: 1.4em;
  font-weight: bold;
  margin: 0;
}

#report-container p {
  margin: 0;
}

#report-container br {  
    content: "A";
    display: block;
    margin-bottom: 1.5em;
}

#report-container a {
  color: blue;
}

#report-container table {
  margin: 1em;
}

#report-container ul {
  list-style-type: disc;
  list-style: outside;
  margin: 0;
  padding: 0 1.5em;
}

#report-container li {
  margin: 0;
}

#report-container .mentions {
  color: red;
  font-weight: bold;
}
